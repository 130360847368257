import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'
import { pdfSignatureFormat } from '../../../../utils/pdfSignature'

function SignatureCancellationNonWeb({ fillableData, styles }) {
  return (
    <View style={{ gap: '10px', width: '100%' }}>
      <View>
        <Text style={{ lineHeight: 1.5, textAlign: 'justify' }}>
          sample, we may collect a saliva or blood sample instead. Blood or
          saliva samples may be sent to a specialty lab where additional charges
          may occur. Patient drug testing will be based on the PCSO Urine Drug
          Testing Algorithm and proprietary Patient Randomizing Software. Urine
          testing may occur at clinic visit and/or walk-in testing. Patients who
          are informed that a urine sample is required will have 3 days to
          provide the urine sample. Some patients at high doses and high risk
          may be required to have two (2) urine drug tests within a 30-day
          period. Patients may be required to provide random urine drug test
          samples, cognitive testing, pharmacy reports and pill counts at any
          time. These guidelines promote safe and appropriate patient medication
          use. You will be responsible for all bills associated with these
          required tests per your insurance benefits.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'center', ...styles.title }}>
          PATIENT INFORMED CONSENT FORM FOR POST EXPOSURE LABORATORY TESTS
        </Text>
        <Text style={{ paddingTop: 8, textAlign: 'justify', lineHeight: 1.5 }}>
          PCSO is requesting your permission to have your blood drawn and tested
          for Hepatitis B Surface Antigen, Hepatitis C Surface Antigen, and
          Human Immunodeficiency Virus in case an employee or physician is
          accidently exposed to your blood or contaminated body fluids. In order
          to establish if our employee or physician has been exposed to one of
          these infectious diseases, the Occupational Safety and Health
          Administration (OSHA) requires permission from you to perform these
          tests.
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text>You will not be financially obligated for these tests.</Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={{ textAlign: 'justify', lineHeight: 1.5 }}>
          The records of the test results will be kept confidential and are not
          disclosed without your written consent to any person within or outside
          the facility except as required by law. A copy will be provided to you
          at your request.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'justify', lineHeight: 1.5 }}>
          I have read the above statement and understand the importance of this
          lab test process for the protection of Pain Care Specialists of Oregon
          employees and physicians.
        </Text>
      </View>
      <View>
        <Text style={{ textAlign: 'justify', lineHeight: 1.5 }}>
          I do agree to have the above tests done, and I understand the test
          results will be acquired by Pain Care Specialists of Oregon, LLC.
        </Text>
      </View>
      <View>
        <Text style={{ paddingTop: 20, ...styles.title }}>
          By signing this form, you agree that you have read and understand the
          above policies.
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: 10,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '10px',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          {fillableData.patientSigning.includes('data:image/') ? (
            <Image
              style={{
                width: '120px',
                objectFit: 'cover',
              }}
              src={fillableData.patientSigning}
            />
          ) : (
            <Text
              style={{ width: '125px', textAlign: 'center', paddingTop: 14 }}
            >
              {fillableData.patientSigning}
            </Text>
          )}
          <Text style={{ width: '175px', textAlign: 'center' }}>
            {fillableData?.createdAt
              ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text>Patient of Legal Representative Name</Text>
          <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
        </View>
      </View>

      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '60px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          <Image
            style={{
              width: '125px',
              objectFit: 'cover',
            }}
            src={pdfSignatureFormat(fillableData.signaturePatientLaw)}
          />
          {/* {fillableData.signaturePatientLaw.includes('data:image/') ? (
            <Image
              style={{
                width: '120px',
                objectFit: 'cover',
              }}
              src={fillableData.signaturePatientLaw}
            />
          ) : (
            <Text
              style={{ width: '125px', textAlign: 'center', paddingTop: 14 }}
            >
              {fillableData.signaturePatientLaw}
            </Text>
          )} */}
          <Text style={{ width: '175px', textAlign: 'center' }}>
            {fillableData?.createdAt
              ? moment(fillableData?.createdAt).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text>Signature of Legal Representative</Text>
          <Text style={{ width: '175px', textAlign: 'center' }}>Date</Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          paddingTop: '10px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: '2px',
          }}
        >
          <Text style={{ width: '120px', textAlign: 'center' }}>
            {fillableData?.DOB
              ? moment(fillableData?.DOB).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
        <View
          style={{
            borderTop: '1px dotted #000',
            width: '100%',
          }}
        ></View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            paddingTop: '2px',
          }}
        >
          <Text style={{ textAlign: 'start' }}>Patient's Date of Birth</Text>
        </View>
      </View>
    </View>
  )
}

export default SignatureCancellationNonWeb
