import { Center, Text } from '@chakra-ui/react'
import React from 'react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalWebEnablePages } from '../../config'

function AssignmentBenefits({ formView }) {
  return (
    <CardContent>
      <Center>
        <Text fontWeight="bold">
          {`ASSIGNMENT OF BENEFITS FOR LABORATORY TESTING`}
        </Text>
      </Center>
      <Text pt="20PX">
        {`I, the above named patient, understand that services rendered to me by Pain Care Specialists or Oregon, LLC physicians and/or other healthcare providers are my financial responsibility and that the provider will bill my insurance company, listed above, as a courtesy. I authorize my insurance company to pay my benefits directly to Pain Care Specialists or Oregon, LLC and I understand that I will be fully responsible for any outstanding balance on my account.`}
        <span style={{ fontWeight: 'bold' }}>
          {`THIS IS A DIRECT ASSIGNMENT OF MY RIGHTS AND BENEFITS UNDER THIS POLICY.`}
        </span>
        {`This payment will not exceed my indebtedness to the above-mentioned assignee and I have agreed to pay, in a current manner, any balance of said professional service charges over and above this insurance payment.`}
      </Text>
      <Text pt="20px">
        {`I have been given the opportunity to pay my estimated deductible and co-insurance at the time of service. I have chosen to assign the benefits, knowing that the claim must be paid within all state or federal prompt payment guidelines. I will provide all relevant and accurate information to facilitate the prompt payment of the claim by my insurance company.`}
      </Text>
      <Text pt="20px">
        {`I authorize the provider to release any information necessary to adjudicate the claim and understand that there may be associated costs for providing information beyond what is necessary for the adjudication of a clean claim. In providing this consent, I am fully aware that the physicians of Pain Care Specialists or Oregon, LLC, the staff and employees cannot be responsible for the confidentiality of the information disclosed after medical records have been released. Therefore, the physicians of Pain Care Specialists or Oregon, LLC, the staff, and employees are released from liability arising from such disclosure.`}
      </Text>
      <Text pt="20px">
        {`I also understand that should my insurance company send payment to me, I will forward the payment to Pain Care Specialists or Oregon, LLC within forty-eight (48) hours. I agree that if I fail to send the payment to Pain Care Specialists or Oregon, LLC and they are forced to proceed with the collections process; I will be responsible for any cost incurred by the office to retrieve their monies. In the event patient receives any check, draft or other payment subject to this agreement, I will immediately deliver said check, draft or payment to provider. Any violations of this agreement will, at provider's election, terminate patient charge privileges with provider and bring any balance owed by patient to provider immediately due and payable.`}
      </Text>
      <Text pt="20px">
        {`To avoid this additional cost and inconvenience, should the insurance company forward payment to me, I authorize Pain Care Specialists or Oregon, LLC to facilitate payment utilizing the credit card number on file to resolve the balance. A photocopy of this Assignment shall be considered as effective and valid as the original.`}
      </Text>
      <Text pt="20px">
        {`I authorize Pain Care Specialists or Oregon, LLC to initiate a complaint or file appeals to the insurance commissioner or any payer authority for any reason on my behalf and I personally will be active in the resolution of claims delay or unjustified reductions or denials.`}
      </Text>
      <Text pt="20px" fontWeight="bold">
        {`DISCLOSURE OF PHYSICIAN OWNERSHIP`}
      </Text>
      <Text pt="20px">
        {`The physicians at Pain Care Specialists or Oregon, LLC may have, ownership in one or more of the following entities: Pain Care Specialists of Oregon and Oregon Specialists Surgery Center. A list of physician ownership is available upon request.`}
      </Text>
      <Text pt="20px">
        {`You have a right to choose the provider of your health care services. Therefore, you have the option to use a health care facility other than the one to which you were referred.`}
      </Text>
      <Text pt="20px">
        {`You will not be treated differently by your physician if you choose to use a different facility. If desired, your physician can provide information about alternative providers.`}
      </Text>
      <Center pt="2rem">{`11/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}

export { AssignmentBenefits }
