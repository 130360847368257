import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import React from 'react'
import Amendment from '../PagePDFform/WebEnable/Amendment'
import AssignmentBenefits from '../PagePDFform/WebEnable/AssignmentBenefits'
import CancellationNOShowPolicy from '../PagePDFform/WebEnable/CancellationNOShowPolicy'
import LawEnforcement from '../PagePDFform/WebEnable/LawEnforcement'
import NoticePrivacyPractices from '../PagePDFform/WebEnable/NoticePrivacyPractices'
import PainCareSpecialist from '../PagePDFform/WebEnable/PainCareSpecialist'
import PatientDemographics from '../PagePDFform/WebEnable/PatientDemographics'
// import RecruitPatientsSecondForm from '../PagePDFform/WebEnable/RecruitPatientsSecondForm'
// import RecruitPatientsThirdForm from '../PagePDFform/WebEnable/RecruitPatientsThirdForm'
// import RecruitingPatientsFirstForm from '../PagePDFform/WebEnable/RecruitingPatientsFirstForm'
import SignatureAuthorization from '../PagePDFform/WebEnable/SignatureAuthorization'
import SignatureCancellation from '../PagePDFform/WebEnable/SignatureCancellation'
import SignatureCancellationSecond from '../PagePDFform/WebEnable/SignatureCancellationSecond'
import SignaturePermission from '../PagePDFform/WebEnable/SignaturePermission'
import Soap from '../PagePDFform/WebEnable/Soap'
import SoapPageTwo from '../PagePDFform/WebEnable/SoapPageTwo'
import TreatmentOptions from '../PagePDFform/WebEnable/TreatmentOptions'
import OswestryWebEnabledPDF from '../PagePDFform/WebEnable/Oswestry'
import RecruitPatients from '../PagePDFform/WebEnable/RecruitPatients'
import PHQPDF from '../PagePDFform/WebEnable/PHQ'
import GADPDF from '../PagePDFform/WebEnable/GAD'

function FormWebEnableForm({ fillableData }) {
  const calPHQStyle = () => {
    if (fillableData?.phqScoreType === 'Mild') return -65
    if (fillableData?.phqScoreType === 'Moderate') return 80
    if (fillableData?.phqScoreType === 'Moderately Severe') return 210
    if (fillableData?.phqScoreType === 'Severe Depression') return 335
    return -1000
  }

  const calGADStyle = () => {
    if (fillableData?.gadScoreType === 'Mild') return -65
    if (fillableData?.gadScoreType === 'Moderate') return 80
    if (fillableData?.gadScoreType === 'Moderately Severe Anxiety') return 210
    if (fillableData?.gadScoreType === 'Severe Anxiety') return 335
    return -1000
  }

  const styles = StyleSheet.create({
    pdfView: {
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: '#fff',
    },
    page: {
      width: '100%',
      flexDirection: 'column',
      backgroundColor: '#fff',
      alignItems: 'center',
      fontSize: 10,
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 50,
      paddingBottom: 30,
    },
    imgLogo: {
      width: '140px',
      objectFit: 'cover',
    },
    gapBox: {
      gap: '14px',
    },
    gapText: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
    },
    fontDot: {
      fontSize: 12,
    },
    paddingLeftFormTitle: {
      width: '100%',
      height: '15px',
      display: 'flex',
      alignItems: 'start',
      backgroundColor: '#d3d3d3',
      justifyContent: 'center',
      fontWeight: 'bold',
    },
    paddingLeftForm: {
      paddingLeft: 10,
    },
    paddingLeftFormData: {
      width: '100%',
      borderBottom: '1px dotted #000',
      paddingLeft: 4,
    },
    boxRowData: {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
    },
    trueFalseChoiceRow: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '14px',
      fontSize: 10,
    },
    trueFalseChoicewidth: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    trueFalseChoiceContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '6px',
      fontSize: 10,
    },
    stylesCheckImage: {
      border: 1,
      width: '14px',
      height: '13px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imgcheck: {
      width: '10px',
      height: '10px',
    },
    sectionthree: {
      width: '100%',
      marginTop: 20,
      marginBottom: 20,
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    table: {
      display: 'table',
      borderStyle: 'solid',
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    socialHistory: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignContent: 'center',
      marginBottom: '6px',
    },
    title: {
      color: '#045f96',
    },
  })

  const stylesSoap = StyleSheet.create({
    boxBorderTitle: {
      width: '100%',
      border: 1,
      borderBottom: 0,
      padding: 4,
    },
    subBoxTitle: {
      fontWeight: 'bold',
      transform: 'rotate(-90deg)',
      position: 'absolute',
      marginTop: '25px',
      width: '55px',
    },
    boxBorder: {
      width: '45px',
      border: 1,
      alignItems: 'center',
      alignContent: 'center',
      borderLeft: 0,
      borderBottom: 0,
      padding: 2,
    },
  })

  const stylesOswestry = StyleSheet.create({
    sectionTable: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    boxTopLeft: {
      width: '50%',
      borderTop: 1,
      borderLeft: 1,
      borderRight: 1,
      padding: '1px 16px 12px 6px',
    },
    boxBottomLeft: {
      width: '50%',
      borderTop: 1,
      borderLeft: 1,
      borderRight: 1,
      borderBottom: 1,
      padding: '1px 16px 12px 6px',
    },
    boxTopRight: {
      width: '50%',
      borderTop: 1,
      borderRight: 1,
      padding: '1px 16px 1px 6px',
    },
    boxBottomRight: {
      width: '50%',
      borderTop: 1,
      borderRight: 1,
      borderBottom: 1,
      padding: '1px 16px 1px 6px',
    },
    svg: {
      width: '15px',
      height: '10px',
    },
    boxAnswer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
    },
    textQuestion: {
      fontSize: '10px',
      marginBottom: '10px',
    },
    textAnswer: {
      fontSize: '9px',
      marginBottom: '2px',
    },
    boxScore: {
      width: '100%',
      paddingLeft: '20px',
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
    },
    textTitleScore: {
      fontSize: '12px',
      textAlign: 'start',
    },
    textScore: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '100px',
    },
    boxCheck: {
      position: 'absolute',
      top: -3.5,
      left: 4,
    },
    imgcheck: {
      width: '10px',
      height: '10px',
    },
  })

  const stylesPHQGAD = StyleSheet.create({
    section: {
      width: '100%',
      marginBottom: 10,
    },
    textTitle: {
      fontSize: '12px',
    },
    sectionTwo: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boxmonthyearIn: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    textmonthyear: {
      fontSize: '12px',
    },
    textDate: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '80px',
    },
    textName: {
      fontSize: '12px',
      borderBottom: 1,
      textAlign: 'center',
      width: '160px',
    },
    boxHelperTable: {
      width: '100%',
      display: 'flex',
      marginTop: '5px',
      flexDirection: 'row',
    },
    boxHelperTableSecond: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    textHelperTable: {
      fontSize: '10px',
    },
    textHelperTableUnderline: {
      fontSize: '10px',
      textDecoration: 'underline',
    },
    sectionthree: {
      width: '100%',
      marginBottom: 10,
      flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'start',
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColTitleHeader: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    tableColTitle: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColTitleTotal: {
      width: '55%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    tableColOne: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColFirstPHQ: {
      paddingTop: 5,
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColOneTotal: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableColTwo: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColTwoTotal: {
      width: '15%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableColThree: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColThreeTotal: {
      width: '10%',
      borderStyle: 'solid',
      borderWidth: 2,
      borderLeftWidth: 0,
      borderTopWidth: 2,
    },
    tableCellHeader: {
      marginTop: 2,
      fontSize: 10,
      textAlign: 'center',
    },
    tableCell: {
      margin: 'auto',
      marginY: 1,
      fontSize: 10,
    },
    tableCellQuestion: {
      margin: '1px 1px',
      fontSize: 10,
    },
    imgcheck: {
      width: '7px',
      height: '7px',
    },
    sectionTotalScore: {
      width: '100%',
      marginBottom: 5,
    },
    boxTotalScore: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
    },
    sectionBox: {
      width: '100%',
      position: 'relative',
    },
    sectionResult: {
      width: '80vw',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: 10,
      marginTop: 10,
    },
    textResult: {
      fontSize: 10,
      textAlign: 'start',
    },
    circlePHQ: {
      position: 'absolute',
      left: calPHQStyle(),
    },
    circleGAD: {
      position: 'absolute',
      left: calGADStyle(),
    },
    boxTextRev: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '9px',
    },
    boxTextFooter: {
      paddingHorizontal: '30px',
    },
    textFooter: {
      fontSize: '8px',
      textAlign: 'center',
    },
    gapBox: {
      gap: '14px',
    },
  })

  return (
    <PDFViewer style={styles.pdfView}>
      <Document>
        {/*<------------------------- page cover ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingBottom: 0 }}>
          <View
            style={{
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'center',
              fontSize: '24px',
              marginTop: '-30px',
            }}
          >
            <Text>NOTICE OF PRIVACY PRACTICES</Text>
          </View>
          <View
            style={{
              marginTop: '-110px',
              height: '75px',
              width: '100%',
              display: 'flex',
              alignItems: 'end',
              justifyContent: 'end',
              left: '340px',
            }}
          >
            <Image style={styles.imgLogo} src="/paincare-web-v2.png" />
          </View>
        </Page>

        {/*<------------------------- page 1 ------------------------->*/}
        <Page size="A4" style={styles.page}>
          <NoticePrivacyPractices styles={styles} />
        </Page>

        {/*<------------------------- page 2 ------------------------->*/}
        <Page size="A4" style={styles.page}>
          <TreatmentOptions styles={styles} />
        </Page>

        {/*<------------------------- page 3 ------------------------->*/}
        <Page size="A4" style={styles.page}>
          <LawEnforcement styles={styles} />
        </Page>

        {/*<------------------------- page 4 ------------------------->*/}
        <Page size="A4" style={styles.page}>
          <Amendment styles={styles} />
        </Page>

        {/*<------------------------- page 5 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 10 }}>
          <PainCareSpecialist fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 6 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <PatientDemographics fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 7 ------------------------->*/}
        <Page
          size="A4"
          style={{ ...styles.page, paddingTop: 25, paddingBottom: 10 }}
        >
          <RecruitPatients fillableData={fillableData} styles={styles} />
          {/* <RecruitingPatientsFirstForm
            fillableData={fillableData}
            styles={styles}
          /> */}
        </Page>

        {/*<------------------------- page 8 ------------------------->*/}
        <Page
          size="A4"
          style={{ ...styles.page, paddingTop: 25, paddingBottom: 10 }}
        >
          <PHQPDF
            fillableData={fillableData}
            styles={styles}
            stylePHQGAD={stylesPHQGAD}
          />
        </Page>

        {/*<------------------------- page 9 ------------------------->*/}
        <Page
          size="A4"
          style={{ ...styles.page, paddingTop: 25, paddingBottom: 10 }}
        >
          <GADPDF
            fillableData={fillableData}
            styles={styles}
            stylePHQGAD={stylesPHQGAD}
          />
        </Page>

        {/*<------------------------- page 8 ------------------------->*/}
        {/* <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <RecruitPatientsSecondForm
            fillableData={fillableData}
            styles={styles}
          />
        </Page> */}

        {/*<------------------------- page 9 ------------------------->*/}
        {/* <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <RecruitPatientsThirdForm
            fillableData={fillableData}
            styles={styles}
          />
        </Page> */}

        {/*<------------------------- page 10 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <Soap
            fillableData={fillableData}
            styles={styles}
            stylesSoap={stylesSoap}
          />
        </Page>

        {/*<------------------------- page 11 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <SoapPageTwo
            fillableData={fillableData}
            styles={styles}
            stylesSoap={stylesSoap}
          />
        </Page>

        {/*<------------------------- page 12 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <OswestryWebEnabledPDF
            fillableData={fillableData}
            styles={styles}
            stylesOswestry={stylesOswestry}
          />
        </Page>

        {/*<------------------------- page 13 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 25 }}>
          <AssignmentBenefits styles={styles} />
        </Page>

        {/*<------------------------- page 14 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <CancellationNOShowPolicy styles={styles} />
        </Page>

        {/*<------------------------- page 15 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <SignatureCancellation styles={styles} />
        </Page>

        {/*<------------------------- page 16 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page }}>
          <SignatureCancellationSecond
            fillableData={fillableData}
            styles={styles}
          />
        </Page>

        {/*<------------------------- page 17 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <SignatureAuthorization fillableData={fillableData} styles={styles} />
        </Page>

        {/*<------------------------- page 18 ------------------------->*/}
        <Page size="A4" style={{ ...styles.page, paddingTop: 40 }}>
          <SignaturePermission fillableData={fillableData} styles={styles} />
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default FormWebEnableForm
