import moment from 'moment'
import React from 'react'

import { Text, Image, View, Svg, Ellipse } from '@react-pdf/renderer'
import { phqQuizData } from '../../../PatientForm/form/formPHQGAD'

export default function PHQPDF({ fillableData, styles, stylePHQGAD }) {
  const checked = (key, value) => {
    if (key !== value) return ''
    return <Image style={stylePHQGAD.imgcheck} src="/check.png" />
  }

  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={stylePHQGAD.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={stylePHQGAD.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View style={stylePHQGAD.section}>
        <Text style={stylePHQGAD.textTitle}>
          Patient Health Questionnaire (PHQ-9)
        </Text>
      </View>
      <View style={stylePHQGAD.sectionTwo}>
        {/* <View style={stylePHQGAD.boxmonthyearIn}>
          <Text style={stylePHQGAD.textmonthyear}>Date :</Text>
          <Text style={stylePHQGAD.textDate}>
            {fillableData?.date
              ? `${moment(fillableData?.date).format('MM/DD/YYYY')}`
              : '-'}
          </Text>
        </View> */}
        <View style={stylePHQGAD.boxmonthyearIn}>
          <Text style={stylePHQGAD.textmonthyear}>Patient name :</Text>
          <Text style={stylePHQGAD.textName}>
            {fillableData?.namePatient || '-'}
          </Text>
        </View>
        <View style={stylePHQGAD.boxmonthyearIn}>
          <Text style={stylePHQGAD.textmonthyear}>Date of birth :</Text>
          <Text style={stylePHQGAD.textDate}>
            {fillableData?.DOB
              ? moment(fillableData?.DOB).format('MM/DD/YYYY')
              : ''}
          </Text>
        </View>
      </View>
      <View style={stylePHQGAD.boxHelperTable}>
        <View>
          <Text style={stylePHQGAD.textHelperTable}>Over the </Text>
        </View>
        <View>
          <Text style={stylePHQGAD.textHelperTableUnderline}>last 2 weeks</Text>
        </View>
        <View>
          <Text style={stylePHQGAD.textHelperTable}>
            , how often have you been bothered by any of the following problems?
          </Text>
        </View>
      </View>
      <View style={stylePHQGAD.boxHelperTableSecond}>
        <Text style={stylePHQGAD.textHelperTable}>
          Please check your answers.
        </Text>
      </View>
      <View style={stylePHQGAD.sectionthree}>
        <View style={stylePHQGAD.table}>
          <View style={stylePHQGAD.tableRow}>
            <View style={stylePHQGAD.tableColTitleHeader}>
              <Text style={stylePHQGAD.tableCellQuestion}>PHQ-9</Text>
            </View>
            <View style={stylePHQGAD.tableColFirstPHQ}>
              <Text style={stylePHQGAD.tableCellHeader}>Not at all</Text>
            </View>
            <View style={stylePHQGAD.tableColOne}>
              <Text style={stylePHQGAD.tableCellHeader}>Several days</Text>
            </View>
            <View style={stylePHQGAD.tableColTwo}>
              <Text style={stylePHQGAD.tableCellHeader}>
                More than half the days
              </Text>
            </View>
            <View style={stylePHQGAD.tableColThree}>
              <Text style={stylePHQGAD.tableCellHeader}>Nearly every day</Text>
            </View>
          </View>
          <View style={stylePHQGAD.tableRow}>
            <View style={stylePHQGAD.tableColTitle}>
              <Text style={stylePHQGAD.tableCellQuestion}>Score</Text>
            </View>
            <View style={stylePHQGAD.tableColOne}>
              <Text style={stylePHQGAD.tableCell}>0</Text>
            </View>
            <View style={stylePHQGAD.tableColOne}>
              <Text style={stylePHQGAD.tableCell}>1</Text>
            </View>
            <View style={stylePHQGAD.tableColTwo}>
              <Text style={stylePHQGAD.tableCell}>2</Text>
            </View>
            <View style={stylePHQGAD.tableColThree}>
              <Text style={stylePHQGAD.tableCell}>3</Text>
            </View>
          </View>
          {phqQuizData.map((item, index) => (
            <View key={index} style={stylePHQGAD.tableRow}>
              <View style={stylePHQGAD.tableColTitle}>
                <Text style={stylePHQGAD.tableCellQuestion}>{item}</Text>
              </View>
              <View style={stylePHQGAD.tableColOne}>
                <Text style={stylePHQGAD.tableCell}>
                  {checked(fillableData?.phqAnswers?.[index], '0')}
                </Text>
              </View>
              <View style={stylePHQGAD.tableColOne}>
                <Text style={stylePHQGAD.tableCell}>
                  {checked(fillableData?.phqAnswers?.[index], '1')}
                </Text>
              </View>
              <View style={stylePHQGAD.tableColTwo}>
                <Text style={stylePHQGAD.tableCell}>
                  {checked(fillableData?.phqAnswers?.[index], '2')}
                </Text>
              </View>
              <View style={stylePHQGAD.tableColThree}>
                <Text style={stylePHQGAD.tableCell}>
                  {checked(fillableData?.phqAnswers?.[index], '3')}
                </Text>
              </View>
            </View>
          ))}
          <View style={stylePHQGAD.tableRow}>
            <View style={stylePHQGAD.tableColTitleTotal}>
              <Text style={stylePHQGAD.tableCellQuestion}>
                The score for each column
              </Text>
            </View>
            <View style={stylePHQGAD.tableColOneTotal}>
              <Text style={stylePHQGAD.tableCell}>0</Text>
            </View>
            <View style={stylePHQGAD.tableColOneTotal}>
              <Text style={stylePHQGAD.tableCell}>
                {fillableData?.phqScore?.resultOne || 0}
              </Text>
            </View>
            <View style={stylePHQGAD.tableColTwoTotal}>
              <Text style={stylePHQGAD.tableCell}>
                {fillableData?.phqScore?.resultTwo || 0}
              </Text>
            </View>
            <View style={stylePHQGAD.tableColThreeTotal}>
              <Text style={stylePHQGAD.tableCell}>
                {fillableData?.phqScore?.resultThree || 0}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={stylePHQGAD.sectionTotalScore}>
        <View style={stylePHQGAD.boxTotalScore}>
          <Text style={stylePHQGAD.textmonthyear}>
            Total Score (add your column scores) :
          </Text>
          <Text style={stylePHQGAD.textDate}>
            {fillableData?.phqScore?.total || 0}
          </Text>
        </View>
      </View>
      <View style={stylePHQGAD.sectionBox}>
        <View style={stylePHQGAD.sectionResult}>
          <Text style={stylePHQGAD.textResult}>Not difficult at all</Text>
          <Text style={stylePHQGAD.textResult}>Somewhat difficult</Text>
          <Text style={stylePHQGAD.textResult}>Very Difficult</Text>
          <Text style={stylePHQGAD.textResult}>Extremely Difficult</Text>
        </View>
        <View style={stylePHQGAD.circlePHQ}>
          <Svg viewBox="0 0 600 300" style={{ width: 600, height: 300 }}>
            <Ellipse
              cx={100}
              cy={18}
              rx={80}
              ry={15}
              stroke="gray"
              strokeWidth={1}
              fill="none"
            />
          </Svg>
        </View>
      </View>
    </View>
  )
}
