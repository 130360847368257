import React from 'react'
import {
  Box,
  Text,
  Flex,
  VStack,
  GridItem,
  Center,
  HStack,
  FormControl,
  Grid,
  Input,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { SwitchInputComponent } from '../../../switchInput'
import { totalWebEnablePages } from '../../config'

function SignatureCancellationSecond({ formView }) {
  const {
    control,
    // formState: { errors },
  } = useForm()

  return (
    <CardContent>
      <Box>
        <Text>
          I have read the above statement and understand the importance of this
          lab test process for the protection of Pain Care Specialists of
          Oregon, LLC employees and physicians.
        </Text>

        <Text pt="20px">
          I do agree to have the above tests done, and I understand the test
          results will be acquired by Pain Care Specialists of Oregon, LLC.
        </Text>

        <Text pt="20px" fontWeight="bold">
          By signing this form, you agree that you have read and understand the
          above policies.
        </Text>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="patientSigning"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Patient of Legal Representative Name
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text color="black">{formView.patientSigning}</Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="signature"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of Legal Representative
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatientLaw || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>

                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>

              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center pt={{ base: '1.5rem' }}>
                <Text fontWeight="bold" fontSize="15">
                  Patient's Date of Birth
                </Text>
              </Center>

              <Center pt="1rem" w="full">
                <Controller
                  name="authorizationDOB"
                  control={control}
                  defaultValue={moment(new Date()).format('yyyy-MM-DD')}
                  render={props => (
                    <FormControl w="full">
                      <Box
                        borderBottom="1px"
                        borderBottomColor="gray.300"
                        w="full"
                      >
                        <Input
                          isReadOnly
                          type={'date'}
                          value={props.value}
                          onChange={props.onChange}
                          onBlur={props.onBlur}
                          variant="unstyled"
                          textAlign="center"
                        />
                      </Box>
                    </FormControl>
                  )}
                />
              </Center>
            </VStack>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={4}
          h="max-content"
        ></Grid>
      </Box>
      <Center pt="2rem">{`14/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}

export { SignatureCancellationSecond }
