export const pdfSignatureFormat = signature => {
  if (signature.includes('data:image/')) {
    return signature
  } else {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = 300
    canvas.height = 48

    // Set canvas styling
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)

    // Preserve the original case by not modifying the signaturePatient text
    context.font =
      '24px "Brush Script MT", "Comic Sans MS", "Segoe Script", "Bradley Hand", cursive'
    // context.font =
    //   '24px "Dancing Script", "Alex Brush", "Great Vibes", "Brush Script MT", "Comic Sans MS", cursive'
    context.fillStyle = 'black'
    context.textAlign = 'center'
    context.textBaseline = 'middle'

    // Draw the text with original case preserved
    context.fillText(signature, canvas.width / 2, canvas.height / 2)

    // Convert to base64
    const base64Image = canvas.toDataURL('image/png')
    return base64Image
  }
}
