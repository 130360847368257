import React from 'react'
import { Center, Box, Text, ListItem, UnorderedList } from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalNonWebEnabledPage } from '../..'

function SecondNoticePrivacyPractices({ formView }) {
  return (
    <CardContent>
      <Box>
        <Center fontWeight="bold" fontSize="20px">
          {`NOTICE OF PRIVACY PRACTICES`}
        </Center>
        <Text pt="40px">
          <span
            style={{ fontWeight: 'bold' }}
          >{`Release of Information to Family/ Friends. `}</span>
          {`Our practice may release your PHI to a friend or family member who is
   involved in your care or who assists in taking care of you. For example, a guardian may ask that a neighbor take a parent
   or child to the physician's office for treatment. This neighbor may have access to this patient's medical information. We
   may also release information to friends or family members involved in your payment for health services we provide.`}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>
            {`Disclosures Required by Law. `}
          </span>
          {`Our practice will use and disclose your PHI when we are required to do so by federal, state or local law.`}
        </Text>
        <Text pt="15px" fontWeight="bold">
          {`USE AND DISCLOSURE OF YOUR PHI IN CERTAIN SPECIAL CIRCUMSTANCES WITHOUT APPROVAL`}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>{`Public Health Risks. `}</span>
          {`Our practices may disclose your PHI to public health authorities that are authorized by law to collect information for the purpose of:`}
          <UnorderedList pl="10px" pt="15px">
            <ListItem>{`Maintaining vital records such as births and deaths`}</ListItem>
            <ListItem>{`Reporting child abuse or neglect`}</ListItem>
            <ListItem>{`Preventing or controlling disease, injury or disability`}</ListItem>
            <ListItem>{`Notifying a person regarding potential exposure to a communicable disease`}</ListItem>
            <ListItem>{`Reporting reactions to drugs or problems with products or devices`}</ListItem>
            <ListItem>{`Notifying individuals if a product or device has been recalled`}</ListItem>
            <ListItem>{`Notifying appropriate government agencies and authorities regarding domestic abuse, potential abuse or neglect
   of an adult patient; however, we will only disclose this information if the patient agrees or we are required by law
   to disclose this information`}</ListItem>
            <ListItem>{`Notifying your employer under limited circumstances related primarily to workplace injury or illness or medical surveillance`}</ListItem>
          </UnorderedList>
          <Text pt="15px">
            <span style={{ fontWeight: 'bold' }}>
              {`Health Oversight Activities. `}
            </span>
            {`Our practice may disclose your PHI to a health oversight agency for activities authorized
   by law. Oversight activities can include investigations, inspections, audits, surveys, licensure, and disciplinary actions;
   civil, administrative and criminal procedures or actions; or other activities necessary for the government to monitor
   government programs, compliance with civil rights laws and health care system in general. We may use your information
   to report diseases to the health department.`}
          </Text>
          <Text pt="15px">
            <span style={{ fontWeight: 'bold' }}>
              {`Lawsuits and Similar Proceedings. `}
            </span>
            {`Our practice may disclose your PHI in response to a court or administrative order
   if you are involved in a lawsuit or similar proceeding. We also may disclose your PHI in response to a discovery request,
   subpoena, or other lawful process by another party involved in the dispute after we inform you of the request to obtain
   an order protecting the information the party has requested.`}
          </Text>
          <Text fontWeight="bold">{`Law Enforcement. We may release PHI if asked to do so by a law enforcement official:`}</Text>
          <UnorderedList pl="10px" pt="15px">
            <ListItem>{`Regarding a crime victim in certain situations`}</ListItem>
            <ListItem>{`Concerning a death possibly due to criminal conduct`}</ListItem>
            <ListItem>{`Regarding criminal conduct at our offices`}</ListItem>
            <ListItem>{`In response to a warrant, summons, court, order, subpoena or similar legal process`}</ListItem>
            <ListItem>{`To identify/locate a suspect, material witness, fugitive or a missing person`}</ListItem>
            <ListItem>
              {`In an emergency to report a crime (including the location or victim(s) of the crime or the description, identity
   or location of the perpetrator)`}
            </ListItem>
          </UnorderedList>
        </Text>
      </Box>
      <Center pt="2rem">{`10/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { SecondNoticePrivacyPractices }
