import { Image, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

function ThirAcceptingPatients({ fillableData, styles }) {
  return (
    <View style={{ ...styles.gapBox }}>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: 8,
        }}
      >
        <Image
          style={{ width: '110px', objectFit: 'cover' }}
          src="/paincare-web-v2.png"
        />
        <View style={styles.boxtitle}>
          <Text>2480 Liberty Street NE, Suite 180, Salem, OR 97301</Text>
        </View>
        <View style={styles.boxtitle}>
          <Text>Office: (503) 371-1010 Fax: (503) 371-0805</Text>
        </View>
      </View>
      <View
        style={{
          width: '450px',
          paddingTop: '8px',
          gap: '4px',
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.boxRowData}>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '42px' }}>Name :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData.namePatient ? fillableData.namePatient : ''}
              </Text>
            </View>
            <View
              style={{
                ...styles.boxRowData,
                width: '100%',
                gap: '0px',
              }}
            >
              <Text style={{ width: '35px' }}>DOB :</Text>
              <Text style={{ ...styles.paddingLeftFormData }}>
                {fillableData?.DOB
                  ? moment(fillableData?.DOB).format('MM/DD/YYYY')
                  : ''}
              </Text>
            </View>
          </View>
        </View>
        <Text style={{ fontWeight: 'bold' }}>
          New Patient Intake Form, Page 3 of 3
        </Text>
        <View
          style={{
            backgroundColor: '#b0acac',
            width: '100%',
            height: '4px',
          }}
        ></View>
        <View style={{ width: '100%', paddingTop: 12, gap: 2 }}>
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: 10,
              fontWeight: 'bold',
            }}
          >
            Please check Yes or No for each question
          </Text>
          <View
            style={{
              backgroundColor: '#000',
              width: '100%',
              height: '1px',
            }}
          ></View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              paddingBottom: 8,
            }}
          >
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 4,
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Bowel incontinence:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bowelIncontinence === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bowelIncontinence === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Muscle pain and spasm:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.painSpasm === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.painSpasm === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Bladder incontinence:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bladderIncontinence === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bladderIncontinence === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Weakness:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {'patientWeakness' in fillableData &&
                        fillableData?.patientWeakness === 'Yes' ? (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        ) : (
                          fillableData?.weakness === 'Yes' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {'patientWeakness' in fillableData &&
                        fillableData?.patientWeakness === 'No' ? (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        ) : (
                          fillableData?.weakness === 'No' && (
                            <Image
                              style={{
                                ...styles.imgcheck,
                              }}
                              src="/check.png"
                            />
                          )
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Nausea:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.nausea === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.nausea === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Headache:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.headache === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.headache === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Vomiting:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.vomiting === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.vomiting === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Tingling/numbness:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.tingling === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.tingling === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Fevers:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.fevers === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.fevers === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Constipation:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.constipation === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.constipation === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Chills:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chills === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chills === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Change in vision:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.changeVision === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.changeVision === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Sinus congestion:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.sinusCongestion === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.sinusCongestion === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Shortness of breath:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.shortnessBreath === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.shortnessBreath === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Chest pain:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chestPain === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chestPain === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Depression:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.depression === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.depression === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Weight loss:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.weightLoss === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.weightLoss === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Suicidal thoughts:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.suicidalThoughts === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.suicidalThoughts === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Rash:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.rash === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.rash === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Suicide attempts:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.suicideAttempts === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.suicideAttempts === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Diabetes:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.diabetes === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.diabetes === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Attention deficit disorder:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.attentionDeficit === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.attentionDeficit === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Hearing loss:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hearingLoss === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hearingLoss === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Obsessive compulsive disorder:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.obsessiveCompulsive === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.obsessiveCompulsive === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Chance of being pregnant:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chancePregnant === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.chancePregnant === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Bipolar:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bipolar === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.bipolar === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Trouble swallowing:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.troubleSwallowing === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.troubleSwallowing === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Schizophrenia:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.schizophrenia === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.schizophrenia === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Abnormal bruising or bleeding:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.abnormalBruising === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.abnormalBruising === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Mental health changes:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.mentalHealthChanges === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.mentalHealthChanges === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Hepatitis C positive:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hepatitis === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hepatitis === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Undergoing counseling:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.undergoingCounseling === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.undergoingCounseling === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>HIV positive:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hiv === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.hiv === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>High stress level:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.highStress === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.highStress === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Change in sexual function:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.changeSexual === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.changeSexual === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text>Psychiatric hospitalization:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.psychiatricHospitalization === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.psychiatricHospitalization === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 12,
              }}
            >
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text>Homicidal ideation:</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={{ ...styles.trueFalseChoiceRow, gap: 4 }}>
                    <View style={{ ...styles.trueFalseChoicewidth, gap: 2 }}>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.homicidalIdeation === 'Yes' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                      <Text>Yes</Text>
                    </View>
                    <Text>/</Text>
                    <View
                      style={{ ...styles.trueFalseChoiceContainer, gap: 2 }}
                    >
                      <Text>No</Text>
                      <View style={styles.stylesCheckImage}>
                        {fillableData?.homicidalIdeation === 'No' && (
                          <Image
                            style={{
                              ...styles.imgcheck,
                            }}
                            src="/check.png"
                          />
                        )}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              ></View>
            </View>
          </View>
          <View
            style={{
              borderTop: '1px solid #000',
              width: '100%',
            }}
          ></View>
        </View>
      </View>
    </View>
  )
}

export default ThirAcceptingPatients
