import React from 'react'
import {
  Center,
  Text,
  Box,
  Grid,
  FormControl,
  GridItem,
  VStack,
  HStack,
  Flex,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { CardContent } from '../../../../../../Components/Card/card'
import { SwitchInputComponent } from '../../../switchInput'
import { totalNonWebEnabledPage } from '../..'
// const _ = require('underscore')

function SignatureCancellation({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    // setValue,
    // watch,
    // clearErrors,
  } = useForm()

  return (
    <CardContent>
      <Box>
        <Text>
          I have read the above statement and understand the importance of this
          lab test process for the protection of Pain Care Specialists of
          Oregon, LLC employees and physicians.
        </Text>
        <Text pt="20px">
          I do agree to have the above tests done, and I understand the test
          results will be acquired by Pain Care Specialists of Oregon, LLC.
        </Text>
        <Text pt="20px" fontWeight="bold">
          By signing this form, you agree that you have read and understand the
          above policies.
        </Text>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={4}
          h="max-content"
          w="full"
        >
          <Controller
            name="patientSigning"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Patient of Legal Representative Name
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex>
                          <Text color="black" fontFamily={'Homemade Apple'}>
                            {formView.patientSigning}
                          </Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  <Text>
                    {moment(new Date(formView.createdAt.toDate())).format(
                      'MM/DD/YYYY'
                    )}
                  </Text>
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="start"
          gap={4}
          h="max-content"
          w="full"
        >
          <Controller
            name="signatureLegal"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature of Legal Representative
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="50px">
                          <SwitchInputComponent
                            formView={formView.signaturePatientLaw || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
              </FormControl>
            )}
          />

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  {moment(new Date(formView.createdAt.toDate())).format(
                    'MM/DD/YYYY'
                  )}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>

          <GridItem w="100%">
            <VStack spacing="0px">
              <Center pt={{ base: '1.5rem' }}>
                <Text fontWeight="bold" fontSize="15">
                  Patient's Date of Birth
                </Text>
              </Center>
              {/* <Center pt="1rem" w="full"> */}
              <Text pt="20px">
                {moment(new Date(formView.DOB)).format('MM/DD/YYYY')}
              </Text>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
        {/* <Grid
          templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
          alignItems="start"
          gap={4}
          h="max-content"
        ></Grid> */}
      </Box>
      <Center pt="2rem">{`17/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { SignatureCancellation }
