import React from 'react'
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react'
import { oswestryQuestions, totalWebEnablePages } from '../../config'
import { CardContent } from '../../../../../../Components/Card/card'
export default function OswestryWebEnabled({ formView }) {
  return (
    <CardContent>
      <Box>
        <Text pb="20px" fontWeight="bold" textAlign="center">
          Oswestry Disability Questionnaire Form
        </Text>
        <Text pb="20px">
          This questionnaire has been designed to give us information as to how
          your pain is affecting your ability to manage in everyday life. Please
          answer by checking one box in each section for the statement which
          applies to you. Thank you.
        </Text>
        {oswestryQuestions.map((item, index) => (
          <FormControl
            key={index}
            mb="5"
            width={['100%', '100%', '100%', '80%']}
          >
            <FormLabel>{item.question}</FormLabel>
            <RadioGroup value={formView?.answers?.[index] || ''}>
              <Stack>
                {item.answers.map((answer, i) => (
                  <Radio value={`${i}`} key={i} isDisabled>
                    {answer}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
        ))}
        <Center pt="2rem">{`10/${totalWebEnablePages}`}</Center>
      </Box>
    </CardContent>
  )
}
