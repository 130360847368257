import { ChevronRightIcon } from '@chakra-ui/icons'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Container,
  HStack,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { search } from 'ss-search'
import { db } from '../../config/firebase'

function Fillable() {
  const history = useHistory()
  const [formList, setFormList] = useState([])
  const [filterType, setFilterType] = useState('All')
  const [isLoading, setLoading] = useState(true)
  const [onSearch, setOnSearch] = useState('')
  // const params = useParams()

  function handleSearch(e) {
    const searchTerm = e.target.value
    setOnSearch(searchTerm)
  }
  useEffect(() => {
    fetchForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterType, onSearch])

  const fetchForm = async () => {
    setLoading(true)
    let qForm
    if (filterType !== 'All') {
      qForm = query(
        collection(db, 'Form'),
        where('type', '==', filterType),
        // where('createdAt', '>=', moment().startOf('year').toDate()),
        // where('createdAt', '<=', moment().endOf('year').toDate()),
        orderBy('createdAt', 'desc')
      )
    } else {
      qForm = query(
        collection(db, 'Form'),
        // where('createdAt', '>=', moment().startOf('year').toDate()),
        // where('createdAt', '<=', moment().endOf('year').toDate()),
        orderBy('createdAt', 'desc')
      )
    }
    const formSnapshot = await getDocs(qForm)
    const formDate = formSnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }))
    const searchKeys = ['namePatient', 'patient', 'person', 'type', 'createdAt']
    const searchText = onSearch
    const searchResults = search(formDate, searchKeys, searchText)
    setFormList(searchResults)
    setLoading(false)
  }
  return (
    <>
      <Container maxW="100%" mt="5">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="gray.500" />}
          bg="gray.100"
          p="1.5"
          borderRadius="0.3em"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/dashboard">
              Dashbaord
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/fillable">
              Patient Form
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Center mt="5" flexDirection="column" alignItems="flex-start">
          <HStack w="30rem">
            <Input
              w="400px"
              type="text"
              onChange={handleSearch}
              placeholder="Search"
            />
            <Select w="400px" onChange={e => setFilterType(e.target.value)}>
              <option value="All">All</option>
              <option value="WebEnableForm">Web Enable Form</option>
              <option value="NonWebEnableForm">Non WebEnable Form</option>
              <option value="NewPatientPacket">New Patient Packet</option>
              <option value="MedicalRecord">Medical Record</option>
            </Select>
          </HStack>
          {isLoading ? (
            <Center w="100%" pt="5rem">
              <span className="loader"></span>
            </Center>
          ) : (
            <TableContainer w="100%" mt="5">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>NAME</Th>
                    <Th>TYPE</Th>
                    <Th>CREATED DATE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {formList.map((e, i) => {
                    return (
                      <Tr
                        key={i}
                        onClick={() => {
                          history.push(`/fillable/view/${e.id}`)
                        }}
                        cursor="pointer"
                      >
                        <Td>{e.namePatient || e.namepatientForm3}</Td>
                        <Td>{switchType(e.type)}</Td>
                        <Td>
                          {moment(e.createdAt.toDate()).format('yyyy-MM-DD')}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Center>
      </Container>
    </>
  )
}

export default Fillable

function switchType(message) {
  switch (message) {
    case 'WebEnableForm':
      return 'Web Enable Form'
    case 'NonWebEnableForm':
      return 'Non Web Enable Form'
    case 'NewPatientPacket':
      return 'New Patient Packet'
    case 'MedicalRecord':
      return 'Medical Record'
    default:
      return message
  }
}
