import {
  Box,
  Text,
  Flex,
  Grid,
  GridItem,
  VStack,
  Center,
  HStack,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { SwitchInputComponent } from '../../../switchInput'
import { totalWebEnablePages } from '../../config'
const _ = require('underscore')

function SignaturePermission({ formView }) {
  const {
    control,
    // formState: { errors },
    // clearErrors,
    setValue,
    // watch,
  } = useForm()
  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])
  return (
    <CardContent>
      <Box>
        <Text align="center" fontWeight="bold">
          PERMISSION TO RELEASE CONFIDENTIAL MEDICAL INFORMATION TO A FAMILY
          MEMBER, FRIEND OR LEGAL REPRESENTAITVE
        </Text>

        <Text pt="20px">
          IMPORTANT NOTE: The law prohibits release of confidential Medical
          information to any entity without the written, voluntary consent of
          the undersigned patient.
        </Text>

        <Text pt="20px">
          I give permission for Pain Care Specialists of Oregon, LLC, or their
          designated representative to communicate information about my medical
          condition and treatment to:
        </Text>
        <VStack spacing="4">
          <Box w="100%">
            {formView?.permission.map((item, index) => (
              <HStack w="100%" key={index}>
                <Box
                  w="100%"
                  key={item?.id}
                  bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                  p="1em"
                  borderRadius="16px"
                  mt="15px"
                >
                  <VStack>
                    <Controller
                      name={`permission.${index}.name`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Name"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={formView?.permission[index]?.name}
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`permission.${index}.Phone`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Phone"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={formView?.permission[index]?.Phone}
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`permission.${index}.relationship`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Relationship"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            bg="#F9F9F9"
                            border="1px solid black"
                            isReadOnly
                            defaultValue={
                              formView?.permission[index]?.relationship
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </VStack>
                </Box>
              </HStack>
            ))}
          </Box>
        </VStack>

        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          alignItems="end"
          gap={0}
          h="max-content"
        >
          <Controller
            name="namePatient"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Printed Name of Patient
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text>{formView.namePatient}</Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={formView.signaturePatient || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />
          <Controller
            name="namePatientPermission"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Printed Name of Representative
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <Text>{formView.namePatientPermission}</Text>
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15">
                        Signature
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="0px">
                          <SwitchInputComponent
                            formView={
                              formView.signaturePatientRepresentative || ''
                            }
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  {formView.createdAt
                    ?.toDate()
                    .toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  {formView.createdAt
                    ?.toDate()
                    .toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`16/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}
export { SignaturePermission }
