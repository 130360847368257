import React, { useEffect } from 'react'
import {
  Center,
  Box,
  Input,
  FormLabel,
  FormControl,
  Flex,
  Text,
  VStack,
  HStack,
  InputGroup,
  InputLeftAddon,
  Textarea,
  CheckboxGroup,
  Grid,
  Checkbox,
  Table,
  Tbody,
  Th,
  Td,
  Tr,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { Controller, useForm } from 'react-hook-form'
// import moment from 'moment'
import { totalNonWebEnabledPage } from '../..'

const _ = require('underscore')

function SecondAcceptingPatients({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // clearErrors,
  } = useForm()

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  const question = [
    {
      title: 'Heart Disease',
      name: 'heartDisease',
    },
    {
      title: 'Lung Disease',
      name: 'lungDisease',
    },
    {
      title: 'Arthritis',
      name: 'arthritis',
    },
    {
      title: 'Cancer',
      name: 'cancer',
    },
    {
      title: 'Stroke',
      name: 'stroke',
    },
  ]

  return (
    <CardContent>
      <Box w="100%">
        <Flex
          pb="10px"
          w="100%"
          direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
        >
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: '100%',
                    }}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box
            w="100%"
            pt={{ base: '10px', md: '0px' }}
            pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
          >
            <Controller
              name="DOB"
              control={control}
              // defaultValue={moment(new Date()).format('yyyy-MM-DD')}
              render={props => (
                <FormControl>
                  <FormLabel>DOB (mm/dd/yyyy)</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    w={{
                      base: '100%',
                      sm: '100%',
                      md: '100%',
                    }}
                    // type="date"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Flex>
        <Text fontWeight="bold" color="#2D3748" pt="20px">
          New Patient Intake Form for Kiosk Check-In, Page 1 of 1{' '}
        </Text>
        <Text fontWeight="bold" fontSize="14px" pt="10px" pl="10px">
          Current Medications
        </Text>
        <VStack spacing="4">
          <Box w="100%">
            {formView?.currentmedications.map((item, index) => (
              <HStack w="100%">
                <Box
                  w="100%"
                  key={item?.id}
                  bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                  p="1em"
                  borderRadius="16px"
                  mt="15px"
                >
                  <VStack>
                    <Controller
                      name={`currentmedications.${index}.name`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Name"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            w={{
                              base: '100%',
                              sm: '100%',
                              md: '100%',
                            }}
                            defaultValue={
                              formView?.currentmedications[index]?.name
                            }
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`currentmedications.${index}.dose`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Dose"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            w={{
                              base: '100%',
                              sm: '100%',
                              md: '100%',
                            }}
                            defaultValue={
                              formView?.currentmedications[index]?.dose
                            }
                          />
                        </InputGroup>
                      )}
                    />
                    <Controller
                      name={`currentmedications.${index}.frequency`}
                      control={control}
                      defaultValue=""
                      render={props => (
                        <InputGroup>
                          <InputLeftAddon
                            children="Frequency"
                            bg="#B7DDE9"
                            color="#FFFFFF"
                          />
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            w={{
                              base: '100%',
                              sm: '100%',
                              md: '100%',
                            }}
                            defaultValue={
                              formView?.currentmedications[index]?.frequency
                            }
                          />
                        </InputGroup>
                      )}
                    />
                  </VStack>
                </Box>
              </HStack>
            ))}
          </Box>
        </VStack>
        <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
          Current Medical Problems
        </Text>
        <Box pt="10px">
          <Controller
            name="currentMedicalProblems"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <Textarea
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  w={{
                    base: '100%',
                    sm: '100%',
                    md: '100%',
                  }}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text fontSize="15px" pt="30px" pl="10px">
          <span style={{ py: '1', fontWeight: 'bold' }}>Allergies:{` `}</span>
          {`(circle all that apply)`}
        </Text>
        <Box pt="20px">
          <Controller
            name="medicationsTriedPage4"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('medicationsTriedPage4', e)
                    // clearErrors("medicationsTriedPage4")
                  }}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Flex flexWrap="wrap">
                    <Checkbox pr="1rem" value="allergiesNone">
                      None
                    </Checkbox>

                    <Checkbox pr="1rem" value="allergiesTape">
                      Tape
                    </Checkbox>
                    <Checkbox pr="1rem" value="allergiesIodine">
                      Iodine
                    </Checkbox>
                    <Checkbox pr="1rem" value="allergiesLatex">
                      Latex
                    </Checkbox>
                    <Checkbox pr="1rem" value="allergiesContrast">
                      Contrast Dye
                    </Checkbox>

                    <HStack>
                      <Checkbox value="allergiesOther"></Checkbox>
                      <Controller
                        name="inputAllergiesOther"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: '100%',
                              }}
                              placeholder="Other"
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack>
                  </Flex>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
          {`Medication Allergies (list)`}
        </Text>
        <Box pt="10px">
          <Controller
            name="medicationAllergies"
            control={control}
            defaultValue=""
            render={props => (
              <FormControl>
                <Textarea
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  w={{
                    base: '100%',
                    sm: '100%',
                    md: '100%',
                  }}
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
        <Text fontSize="14px" pt="25px" pl="10px">
          <span style={{ py: '1', fontWeight: 'bold' }}>
            Surgical History{` `}
          </span>
          {`(Circle all that apply)`}
        </Text>
        <Box pt="10px">
          <Controller
            name="surgical"
            control={control}
            render={props => (
              <FormControl>
                <CheckboxGroup
                  onChange={e => {
                    setValue('surgical', e)
                    // clearErrors("surgical")
                  }}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                  spacing="0px"
                  isDisabled
                >
                  <Grid
                    w="100%"
                    templateColumns={{
                      base: 'repeat(1,1fr)',
                      sm: 'repeat(1,1fr)',
                      md: 'repeat(2,1fr)',
                      lg: 'repeat(3, 1fr)',
                      xl: 'repeat(4,1fr)',
                    }}
                    gap={4}
                  >
                    <Checkbox pr="0.5rem" value="surgicalCardiac">
                      Cardiac/Heart
                    </Checkbox>

                    <Checkbox pr="0.5rem" value="surgicalCervical">
                      Cervical/Neck
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="surgicalLumbar">
                      Lumbar/Back
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="surgicalHipLeft">
                      Hip Left/Right
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="surgicalKnee">
                      Knee Left/Right
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="surgicalShoulder">
                      Shoulder Left/Right
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="surgicalShoulder">
                      Shoulder Left/Right
                    </Checkbox>
                    <Checkbox pr="0.5rem" value="noSurgicalHistory">
                      No Surgical History
                    </Checkbox>

                    {/* <HStack>
                      <Checkbox value="surgicalOther"></Checkbox>
                      <Controller
                        name="inputSurgicalOther"
                        control={control}
                        defaultValue=""
                        render={props => (
                          <FormControl>
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              w={{
                                base: '100%',
                                sm: '100%',
                                md: '100%',
                              }}
                              placeholder="Other"
                              value={props.value}
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack> */}
                  </Grid>
                </CheckboxGroup>
              </FormControl>
            )}
          />
        </Box>
        <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
          Past Hospitalizations
        </Text>
        <Box>
          <VStack spacing="4">
            <Box w="100%">
              {formView?.hospitalizations.map((item, index) => (
                <HStack w="100%">
                  <Box
                    w="100%"
                    key={item?.id}
                    bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                    p="1em"
                    borderRadius="16px"
                    mt="15px"
                  >
                    <VStack>
                      <Controller
                        name={`hospitalizations.${index}.date`}
                        control={control}
                        defaultValue=""
                        render={props => (
                          <InputGroup>
                            <InputLeftAddon
                              children="Date"
                              bg="#B7DDE9"
                              color="#FFFFFF"
                            />
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              defaultValue={
                                formView?.hospitalizations[index]?.date
                              }
                            />
                          </InputGroup>
                        )}
                      />
                      <Controller
                        name={`hospitalizations.${index}.reason`}
                        control={control}
                        defaultValue=""
                        render={props => (
                          <InputGroup>
                            <InputLeftAddon
                              children="reason"
                              bg="#B7DDE9"
                              color="#FFFFFF"
                            />
                            <Input
                              _readOnly={{
                                border: '1px solid #ccc',
                              }}
                              variant="no-effects"
                              readOnly
                              bg="#F9F9F9"
                              border="1px solid black"
                              defaultValue={
                                formView?.hospitalizations[index]?.reason
                              }
                            />
                          </InputGroup>
                        )}
                      />
                    </VStack>
                  </Box>
                </HStack>
              ))}
            </Box>
          </VStack>
        </Box>
        <Text fontSize="14px" pt="40px" pl="10px">
          <span style={{ py: '1', fontWeight: 'bold' }}>
            Family History: {` `}
          </span>
          {`(Please place an X where applicable)`}
        </Text>
        <Box overflow="auto" h="100%">
          <Table variant="unstyled">
            <Tbody p="0px">
              <Th p="0px">
                <Td w={{ base: '400px', lg: '0px' }} p="0px">
                  {' '}
                </Td>
                <Td fontSize="14px" pl="185px">
                  Father{' '}
                </Td>
                <Td fontSize="14px" px="2rem">
                  Mother
                </Td>
                <Td fontSize="14px">Grandparent</Td>
                <Td fontSize="14px" px="1.7rem">
                  Sibling
                </Td>
                <Td fontSize="14px">None</Td>
              </Th>
              {question.map((e, i) => (
                <Tr key={i}>
                  <Tbody>
                    <Td w="0" p="0px">
                      <Text w="180px" textAlign="start">
                        {e.title}
                      </Text>
                    </Td>
                    <Td p="0px">
                      <Controller
                        name={e.name}
                        control={control}
                        render={props => (
                          <FormControl>
                            <CheckBoxFamilyHistory
                              onChange={props.onChange}
                              onBlur={props.onBlur}
                              defaultValue={formView?.[e.name]}
                            />
                          </FormControl>
                        )}
                      />
                    </Td>
                  </Tbody>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <Text fontSize="14px" pt="40px" pl="10px">
          <span style={{ py: '1', fontWeight: 'bold' }}>Social History:</span>
          {`(Please check Yes or No for each question)`}
        </Text>
        <Box overflow="auto" h="100%" pt="25px" pl="5px">
          <Table variant="unstyled">
            <Tbody p="0px">
              <Tr>
                <Tbody>
                  <Td w="0" p="0px">
                    <Text w="180px" textAlign="start">
                      Smoking
                    </Text>
                  </Td>

                  <Td p="0px">
                    <Controller
                      name="smoking"
                      control={control}
                      render={props => (
                        <FormControl>
                          <RadioGroup
                            isDisabled
                            onChange={props.onChange}
                            value={props.value}
                            onBlur={props.onBlur}
                          >
                            <Grid
                              templateColumns="repeat(1, 1fr)"
                              gap={0}
                              pl={{ base: '20px', xl: '25px', '2xl': '32px' }}
                            >
                              <HStack>
                                <Radio value="yesSmoking">Yes</Radio>
                                <HStack
                                  mt={{ base: '10px', '2xl': '0px' }}
                                  pl={{ base: '38px', lg: '45px' }}
                                >
                                  <Radio
                                    value="noSmoking"
                                    pl={{ lg: '12px', xl: '10px' }}
                                  />
                                  <Text>No</Text>
                                  <Controller
                                    name="otherSmoking"
                                    control={control}
                                    defaultValue=""
                                    render={props => (
                                      <FormControl>
                                        <Input
                                          _readOnly={{
                                            border: '1px solid #ccc',
                                          }}
                                          variant="no-effects"
                                          bg="#F9F9F9"
                                          border="1px solid black"
                                          isReadOnly
                                          placeholder="packs per day/week"
                                          value={props.value}
                                          onChange={props.onChange}
                                          onBlur={props.onBlur}
                                        />
                                      </FormControl>
                                    )}
                                  />
                                </HStack>
                              </HStack>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Td>
                </Tbody>
              </Tr>
            </Tbody>
            <HStack pt={{ base: '10px', lg: '10px' }}>
              <Text w="180px" textAlign="start">
                Alcohol{' '}
              </Text>

              <Controller
                name="alcohol"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '60px' }}
                      >
                        <HStack>
                          <Radio value="yesAlcohol">Yes</Radio>
                          <HStack pl={{ base: '38px', lg: '55px' }}>
                            <Radio value="noAlcohol" />
                            <Text>No</Text>
                            <Controller
                              name="otherAlcohol"
                              control={control}
                              defaultValue=""
                              render={props => (
                                <FormControl>
                                  <Input
                                    _readOnly={{
                                      border: '1px solid #ccc',
                                    }}
                                    variant="no-effects"
                                    bg="#F9F9F9"
                                    border="1px solid black"
                                    isReadOnly
                                    placeholder="drinks per day/week"
                                    value={props.value}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                  />
                                </FormControl>
                              )}
                            />
                          </HStack>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '10px', lg: '10px' }}>
              <Text w="180px" textAlign="start">
                Illegal Drugs{' '}
              </Text>

              <Controller
                name="illegalDrugs"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '60px' }}
                      >
                        <HStack>
                          <Radio value="yesIllegalDrugs">Yes</Radio>
                          <HStack pl={{ base: '38px', lg: '55px' }}>
                            <Radio value="noIllegalDrugs" />
                            <Text>No</Text>
                            <Controller
                              name="otherIllegalDrugs"
                              control={control}
                              defaultValue=""
                              render={props => (
                                <FormControl>
                                  <Input
                                    _readOnly={{
                                      border: '1px solid #ccc',
                                    }}
                                    variant="no-effects"
                                    bg="#F9F9F9"
                                    border="1px solid black"
                                    isReadOnly
                                    placeholder="No Date last used"
                                    value={props.value}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                  />
                                </FormControl>
                              )}
                            />
                          </HStack>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '20px', lg: '15px' }}>
              <Text w="180px" textAlign="start">
                Addiction/Dependency{' '}
              </Text>

              <Controller
                name="addictionDependency"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '32px', xl: '37px', '2xl': '45px' }}
                      >
                        <HStack>
                          <Radio value="yesAddictionDependency">
                            <Text fontSize="16px"> Yes</Text>
                          </Radio>
                          <Radio
                            value="noAddictionDependency"
                            pl={{ base: '40px', lg: '55px' }}
                          >
                            <Text fontSize="16px">No</Text>
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '20px', lg: '25px' }}>
              <Text w="180px" textAlign="start">
                Marijuana{' '}
              </Text>

              <Controller
                name="addictionMarijuana"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '58px', '2xl': '62px' }}
                      >
                        <HStack>
                          <Radio pr="0.5rem" value="yesMarijuana">
                            <Text fontSize="16px"> Yes</Text>
                          </Radio>
                          <Radio
                            value="noMarijuana"
                            pl={{ base: '33px', lg: '48px', '2xl': '45px' }}
                          >
                            <Text fontSize="16px">No</Text>
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '25px', lg: '25px' }}>
              <Text w="180px" textAlign="start">
                Married{' '}
              </Text>

              <Controller
                name="married"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '59px' }}
                      >
                        <HStack>
                          <Radio value="yesMarried">
                            <Text fontSize="16px"> Yes</Text>
                          </Radio>
                          <Radio
                            value="noMarried"
                            pl={{ base: '40px', lg: '55px' }}
                          >
                            <Text fontSize="16px">No</Text>
                          </Radio>
                          <Radio
                            value="divorcedMarried"
                            pl={{ base: '40px', lg: '55px' }}
                          >
                            <Text fontSize="16px">Divorced</Text>
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '15px', lg: '15px' }}>
              <Text w="180px" textAlign="start">
                Children{' '}
              </Text>

              <Controller
                name="children"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '60px' }}
                      >
                        <HStack>
                          <HStack>
                            <Radio value="yesChildren" />
                            <Text>Yes</Text>
                            <Controller
                              name="otherChildren"
                              control={control}
                              defaultValue=""
                              render={props => (
                                <FormControl>
                                  <Input
                                    _readOnly={{
                                      border: '1px solid #ccc',
                                    }}
                                    variant="no-effects"
                                    bg="#F9F9F9"
                                    border="1px solid black"
                                    isReadOnly
                                    placeholder="How many?"
                                    value={props.value}
                                    onChange={props.onChange}
                                    onBlur={props.onBlur}
                                  />
                                </FormControl>
                              )}
                            />
                          </HStack>{' '}
                          <Radio
                            value="noChildren"
                            pl={{ base: '30px', lg: '20px' }}
                          >
                            No
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '15px', lg: '15px' }}>
              <Text w="180px" textAlign="start">
                On Disability{' '}
              </Text>

              <Controller
                name="onDisability"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '60px' }}
                      >
                        <HStack>
                          <Radio pr="0.5rem" value="yesOnDisability">
                            <Text fontSize="16px"> Yes</Text>
                          </Radio>
                          <Radio
                            pr="0.5rem"
                            value="noOnDisability"
                            pl={{ base: '31px', lg: '45px' }}
                          >
                            <Text fontSize="16px">No</Text>
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
            <HStack pt={{ base: '15px', lg: '22px' }}>
              <Text w="180px" textAlign="start">
                Currently Working{' '}
              </Text>

              <Controller
                name="currentLyWorking"
                control={control}
                render={props => (
                  <FormControl>
                    <RadioGroup
                      onChange={props.onChange}
                      value={props.value}
                      onBlur={props.onBlur}
                      isDisabled
                    >
                      <Grid
                        templateColumns="repeat(1, 1fr)"
                        gap={0}
                        pl={{ base: '60px' }}
                      >
                        <HStack>
                          <Radio pr="0.5rem" value="yesCurrentLyWorking">
                            <Text fontSize="16px"> Yes</Text>
                          </Radio>
                          <Radio
                            pr="0.5rem"
                            value="noCurrentLyWorking"
                            pl={{ base: '31px', lg: '45px' }}
                          >
                            <Text fontSize="16px">No</Text>
                          </Radio>
                        </HStack>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </HStack>
          </Table>
        </Box>
      </Box>
      <Center pt="2rem">{`4/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { SecondAcceptingPatients }

function CheckBoxFamilyHistory({ onChange, defaultValue }) {
  return (
    <CheckboxGroup isDisabled onChange={onChange} defaultValue={defaultValue}>
      <Td pl={{ base: '25px' }}>
        <Checkbox value="FATHER" />
      </Td>
      <Td pl={{ base: '95px' }}>
        <Checkbox value="MOTHER" />
      </Td>
      <Td pl={{ base: '130px' }}>
        <Checkbox value="GRANDPARENT" />
      </Td>
      <Td pl={{ base: '125px' }}>
        <Checkbox value="SIBLING" />
      </Td>
      <Td pl={{ base: '75px' }}>
        <Checkbox value="none" />
      </Td>
    </CheckboxGroup>
  )
}
