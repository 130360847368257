import React, { useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Spacer,
  Stack,
} from '@chakra-ui/react'
// import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import {
  gadQuizData,
  phqQuizData,
} from '../../../../../PatientForm/form/formPHQGAD'
import { Divider, Radio } from 'antd'
import { totalNonWebEnabledPage } from '../..'

function PHQGADForm({ formView }) {
  const { control, reset } = useForm()

  const myRef = useRef(null)

  useEffect(() => {
    if (formView) {
      reset(formView)
    }
  }, [formView, reset])

  return (
    <CardContent>
      <Box position="fixed" top="70px" right="10px" zIndex="overlay">
        <Button
          onClick={() =>
            window.scrollTo({
              top: myRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
          colorScheme="blue"
          px="0.5rem"
          _focus={{ border: '0px solid transparent' }}
        >
          For Office
        </Button>
      </Box>
      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          Patient Health Questionnaire (PHQ-9)
        </Box>
        <Spacer />
      </Flex>
      <Flex
        pb="10px"
        direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
      >
        <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
        <Box
          w="100%"
          pt={{ base: '10px', md: '0px' }}
          pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="DOB"
            control={control}
            // defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            render={props => (
              <FormControl>
                <FormLabel>DOB (mm/dd/yyyy)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  // type="date"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
      </Flex>
      {phqQuizData.map((item, index) => {
        return (
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            key={index}
          >
            <FormLabel>{item}</FormLabel>
            <Controller
              name={`phqAnswers.${index}`}
              control={control}
              rules={{ required: 'Please select answer' }}
              defaultValue={formView?.phqAnswers?.[index]}
              render={field => {
                return (
                  <RadioGroup {...field}>
                    <Stack>
                      <Radio
                        value="0"
                        disabled
                        checked={formView?.phqAnswers?.[index] === '0'}
                      >
                        Not at all
                      </Radio>
                      <Radio
                        value="1"
                        disabled
                        checked={formView?.phqAnswers?.[index] === '1'}
                      >
                        Several days
                      </Radio>
                      <Radio
                        value="2"
                        disabled
                        checked={formView?.phqAnswers?.[index] === '2'}
                      >
                        More than half the days
                      </Radio>
                      <Radio
                        value="3"
                        disabled
                        checked={formView?.phqAnswers?.[index] === '3'}
                      >
                        Nearly every day
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )
              }}
            />
          </FormControl>
        )
      })}
      <Divider />

      <Flex my="10">
        <Box pt="2" fontSize="18" fontWeight="bold">
          General Anxiety Disorder (GAD-7)
        </Box>
        <Spacer />
      </Flex>
      {gadQuizData.map((item, index) => {
        return (
          <FormControl
            mb="5"
            width={['100%', '100%', '100%', '80%']}
            key={index}
          >
            <FormLabel>{item}</FormLabel>
            <Controller
              name={`gadAnswers.${index}`}
              control={control}
              rules={{ required: 'Please select answer' }}
              defaultValue={formView?.gadAnswers?.[index]}
              readOnly
              render={field => (
                <RadioGroup {...field}>
                  <Stack>
                    <Radio
                      value="0"
                      disabled
                      checked={formView?.gadAnswers?.[index] === '0'}
                    >
                      Not at all
                    </Radio>
                    <Radio
                      value="1"
                      disabled
                      checked={formView?.gadAnswers?.[index] === '1'}
                    >
                      Several days
                    </Radio>
                    <Radio
                      value="2"
                      disabled
                      checked={formView?.gadAnswers?.[index] === '2'}
                    >
                      Over half the days
                    </Radio>
                    <Radio
                      value="3"
                      disabled
                      checked={formView?.gadAnswers?.[index] === '3'}
                    >
                      Nearly every day
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        )
      })}

      <Center pt="2rem">{`6/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}
export { PHQGADForm }
