import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Flex,
  Box,
  Container,
  Spacer,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  ButtonGroup,
  toast,
  Select,
  HStack,
  Text,
} from '@chakra-ui/react'
import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { BsViewStacked } from 'react-icons/bs'
import { AiOutlineFileText } from 'react-icons/ai'
import {
  doc,
  onSnapshot,
  query,
  orderBy,
  collection,
  updateDoc,
  where,
} from 'firebase/firestore'
import { db } from '../../config/firebase'
import renderDate from '../../config/renderDate'
import RenderFormData from '../../Components/RenderFormData'
import moment from 'moment'

export default function InEvisitForm() {
  const [infectionEmergencyForm, setInfectionEmergencyForm] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [status, setStatus] = useState('All')
  const [facility, setFacility] = useState('All')
  // const [period, setPeriod] = useState('All')
  // const [periodOptions, setPeriodOptions] = useState([])
  const params = useParams()

  useEffect(() => {
    let querys = []
    let orderByClauses = [orderBy('createdAt', 'desc')] // Default ordering

    if (status !== 'All') {
      querys.push(where('status', '==', status))
    }
    if (facility !== 'All') {
      querys.push(where('facility', '==', facility))
    }
    // if (period !== 'All') {
    //   const [month, year] = period.split('/')

    //   const startDate = new Date(parseInt(year), parseInt(month) - 1, 1)
    //   const endDate = new Date(parseInt(year), parseInt(month), 0)
    //   endDate.setHours(23, 59, 59, 999)

    //   querys.push(where('monthYear', '>=', startDate))
    //   querys.push(where('monthYear', '<=', endDate))
    //   orderByClauses = [orderBy('monthYear'), ...orderByClauses]
    // }

    const qInfecEmergency = query(
      collection(db, 'InfectionEmergencyForm'),
      ...querys,
      ...orderByClauses // Apply all orderBy clauses
    )
    const unsubscribe = onSnapshot(qInfecEmergency, snapShot => {
      let data = []
      snapShot.forEach(doc => {
        return data.push({
          ...doc.data(),
          id: doc.id,
        })
      })
      setInfectionEmergencyForm(data)
      setSelectedForm(null)
    })
    return () => {
      unsubscribe()
    }
  }, [status, facility])

  // useEffect(() => {
  //   const qInfecEmergency = query(
  //     collection(db, 'InfectionEmergencyForm'),
  //     orderBy('createdAt', 'desc') // Apply all orderBy clauses
  //   )
  //   const unsubscribe = onSnapshot(qInfecEmergency, snapShot => {
  //     let data = []
  //     snapShot.forEach(doc => {
  //       return data.push({
  //         ...doc.data(),
  //         id: doc.id,
  //       })
  //     })
  //     setPeriodOptions(
  //       [
  //         ...new Set(
  //           data.map(form =>
  //             moment.unix(form.monthYear.seconds).format('MM/YYYY')
  //           )
  //         ),
  //       ].sort((a, b) => {
  //         const [monthA, yearA] = a.split('/')
  //         const [monthB, yearB] = b.split('/')
  //         // Compare years first
  //         if (yearA !== yearB) {
  //           return yearA - yearB
  //         }
  //         // If years are equal, compare months
  //         return monthA - monthB
  //       })
  //     )
  //   })
  //   return () => {
  //     unsubscribe()
  //   }
  // }, [])

  function changeStatus(formId, status) {
    updateDoc(doc(db, 'InfectionEmergencyForm', formId), {
      status: status,
      notification: false,
    })
      .then(() => {})
      .catch(e => {
        toast({
          title: 'Change status error',
          description: e.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  return (
    <Container maxW="100%" mt="5" mb="10">
      <Breadcrumb
        spacing="8px"
        separator={<ChevronRightIcon color="gray.500" />}
        bg="gray.100"
        p="1.5"
        borderRadius="0.3em"
      >
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/dashboard">
            Dashbaord
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/infections-and-emergency-visit">
            Infections and emergency visit
          </BreadcrumbLink>
        </BreadcrumbItem>
        {params.id && selectedForm ? (
          <BreadcrumbItem>
            <BreadcrumbLink>
              {selectedForm.name} ({selectedForm.type})
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          params.id && (
            <BreadcrumbItem>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xs"
              />
            </BreadcrumbItem>
          )
        )}
      </Breadcrumb>
      {params.id && selectedForm ? (
        <Box mt="5">
          <Box textAlign="right" mt="5">
            {selectedForm.type === 'CME Request' && selectedForm.attachFile && (
              <Button
                mr="3"
                size="sm"
                variant="outline"
                onClick={() => {
                  window.open(selectedForm.attachFile)
                }}
                leftIcon={<AiOutlineFileText />}
              >
                Download File
              </Button>
            )}

            <ButtonGroup size="sm" isAttached>
              <Button
                colorScheme="green"
                variant={
                  selectedForm.status === 'Approved' ? 'solid' : 'outline'
                }
                mr="-px"
                onClick={() => changeStatus(selectedForm.id, 'Approved')}
              >
                Approved
              </Button>
              <Button
                colorScheme="blue"
                variant={
                  selectedForm.status === 'Pending' ? 'solid' : 'outline'
                }
                mr="-px"
                onClick={() => changeStatus(selectedForm.id, 'Pending')}
              >
                Pending
              </Button>
              <Button
                colorScheme="red"
                variant={
                  selectedForm.status === 'Rejected' ? 'solid' : 'outline'
                }
                mr="-px"
                onClick={() => changeStatus(selectedForm.id, 'Rejected')}
              >
                Rejected
              </Button>
            </ButtonGroup>
          </Box>
          {RenderFormData({ data: selectedForm, toast: toast })}
        </Box>
      ) : (
        <>
          <Flex mt="5">
            <Box
              pt="2"
              fontSize="18"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              Infection and Emergency Form
            </Box>
            <Spacer />
            <Flex gap={4}>
              {/* <Box>
                <Text mb="4px">Month/Year</Text>
                <Select
                  name="monthYear"
                  onChange={e => setPeriod(e.target.value)}
                  defaultValue={period}
                >
                  <option>All</option>
                  {periodOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
              </Box> */}
              <Box>
                <Text mb="4px">Facility</Text>
                <Select
                  name="facility"
                  onChange={e => setFacility(e.target.value)}
                  defaultValue={facility}
                >
                  <option>All</option>
                  <option>PCSO</option>
                  <option>OSSC</option>
                </Select>
              </Box>
              <Box>
                <Text mb="4px">Status</Text>
                <Select
                  name="status"
                  onChange={e => setStatus(e.target.value)}
                  defaultValue={status}
                >
                  <option>All</option>
                  <option>Draft</option>
                  <option>Success</option>
                </Select>
              </Box>
            </Flex>
          </Flex>
          <HStack py="1.75em" justifyContent="end">
            <Button
              as={Link}
              to={`/infections-and-emergency-visit-form`}
              size="xs"
              leftIcon={<AddIcon />}
            >
              create
            </Button>
          </HStack>
          <Table size="sm" mt="10">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Physician</Th>
                <Th>Month/Year</Th>
                <Th>Facility</Th>
                <Th>Status</Th>
                <Th textAlign="center">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {infectionEmergencyForm.map((form, index) => {
                return (
                  <Tr key={index}>
                    <Td>{renderDate(form.createdAt)}</Td>
                    <Td>{form.physician}</Td>
                    <Td>
                      {moment.unix(form.monthYear.seconds).format('MM/YYYY')}
                    </Td>
                    <Td>{form.facility || '-'}</Td>
                    <Td>
                      {form.status === 'Review'
                        ? 'Ready to Review'
                        : form.status}
                    </Td>
                    <Td textAlign="center">
                      <Button
                        // disabled={form?.status === 'success' ? true : false}
                        as={Link}
                        // to={
                        //   form?.status === 'success'
                        //     ? '#'
                        //     : `/infections-and-emergency-visit-form/${form.id}`
                        // }
                        to={`/infections-and-emergency-visit-form/${form.id}`}
                        size="xs"
                        leftIcon={<BsViewStacked />}
                      >
                        View
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Container>
  )
}
