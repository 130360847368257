import React, { useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalWebEnablePages } from '../../config'
const _ = require('underscore')

function RecruitPatientsForm({ formView }) {
  const {
    // reset,
    // register,
    // errors,
    control,
    setValue,
    // watch,
    clearErrors,
  } = useForm()

  const myRef = useRef(null)

  useEffect(() => {
    if (formView) {
      const data = formView
      _.keys(data).map(e => {
        return setValue(e, data[e])
      })
    }
  }, [formView, setValue])

  return (
    <CardContent>
      <Box position="fixed" top="70px" right="10px" zIndex="overlay">
        <Button
          onClick={() =>
            window.scrollTo({
              top: myRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
          colorScheme="blue"
          px="0.5rem"
          _focus={{ border: '0px solid transparent' }}
        >
          For Office
        </Button>
      </Box>
      <Flex
        pb="10px"
        direction={{ base: 'column', sm: 'column', md: 'row', lg: 'row' }}
      >
        <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
          <Box w="100%" pr={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}>
            <Controller
              name="namePatient"
              control={control}
              defaultValue=""
              render={props => (
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    _readOnly={{
                      border: '1px solid #ccc',
                    }}
                    variant="no-effects"
                    readOnly
                    bg="#F9F9F9"
                    border="1px solid black"
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
        <Box
          w="100%"
          pt={{ base: '10px', md: '0px' }}
          pl={{ base: '0px', sm: '0px', md: '10px', lg: '10px' }}
        >
          <Controller
            name="dobPatient"
            control={control}
            defaultValue={moment(new Date()).format('yyyy-MM-DD')}
            render={props => (
              <FormControl>
                <FormLabel>DOB (mm/dd/yy)</FormLabel>
                <Input
                  _readOnly={{
                    border: '1px solid #ccc',
                  }}
                  variant="no-effects"
                  readOnly
                  bg="#F9F9F9"
                  border="1px solid black"
                  type="date"
                  value={props.value}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </FormControl>
            )}
          />
        </Box>
      </Flex>
      <Box>
        <Text pl="10px" pt="30px" pb="10px" fontSize="14px" fontWeight="bold">
          Medications tried:{' '}
          <span style={{ py: '1', fontWeight: 'normal' }}>
            {`(check all that apply)`}
          </span>
        </Text>
        <Controller
          name="medicationsTried"
          control={control}
          render={props => (
            <FormControl>
              <CheckboxGroup
                onChange={e => {
                  setValue('medicationsTried', e)
                  clearErrors('medicationsTried')
                }}
                onBlur={props.onBlur}
                value={props.value}
                w="100%"
                spacing="0px"
              >
                <Grid
                  w="100%"
                  templateColumns={{
                    base: 'repeat(1,1fr)',
                    sm: 'repeat(2,1fr)',
                    md: 'repeat(2,1fr)',
                    lg: 'repeat(3, 1fr)',
                    xl: 'repeat(4,1fr)',
                  }}
                  gap={4}
                >
                  <Checkbox isDisabled pr="0.5rem" value="tIbuprofen">
                    Ibuprofen
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tTylenol">
                    Tylenol
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tNortriptyline">
                    Nortriptyline
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tAmitriptyline">
                    Amitriptyline
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tGabapentin ">
                    Gabapentin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tLyrica">
                    Lyrica
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tFlexeril">
                    Flexeril
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tRobaxin">
                    Robaxin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tZanaflex ">
                    Zanaflex
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tSkelaxin">
                    Skelaxin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tHydrocodone">
                    Hydrocodone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOxycodone">
                    Oxycodone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOxycontin">
                    Oxycontin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMorphine">
                    Morphine
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMS">
                    MS Contin
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tMethadone">
                    Methadone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tDilaudid">
                    Dilaudid
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tSuboxone">
                    Suboxone
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tNucynta">
                    Nucynta
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tFentanyl">
                    Fentanyl
                  </Checkbox>
                  <Checkbox isDisabled pr="0.5rem" value="tOpana">
                    Opana
                  </Checkbox>
                  <HStack w="auto">
                    <Checkbox isDisabled value="Other"></Checkbox>
                    <Controller
                      name="otherMedications"
                      control={control}
                      defaultValue=""
                      render={props => (
                        <FormControl>
                          <Input
                            _readOnly={{
                              border: '1px solid #ccc',
                            }}
                            variant="no-effects"
                            readOnly
                            bg="#F9F9F9"
                            border="1px solid black"
                            placeholder="Other"
                            value={props.value}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                </Grid>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Text fontWeight="bold" fontSize="14px" pt="10px" pl="10px">
          Current Medications
        </Text>
      </Box>
      <VStack spacing="4">
        <Box w="100%">
          {formView?.medications.map((item, index) => (
            <HStack w="100%">
              <Box
                w="100%"
                key={item?.id}
                bgImage={`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23B7DDE8FF' stroke-width='3' stroke-dasharray='19%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`}
                p="1em"
                borderRadius="16px"
                mt="15px"
              >
                <VStack>
                  <Controller
                    name={`medications.${index}.name`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Name"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.name}
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name={`medications.${index}.dose`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Dose"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.dose}
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name={`medications.${index}.frequency`}
                    control={control}
                    defaultValue=""
                    render={props => (
                      <InputGroup>
                        <InputLeftAddon
                          children="Frequency"
                          bg="#B7DDE9"
                          color="#FFFFFF"
                        />
                        <Input
                          _readOnly={{
                            border: '1px solid #ccc',
                          }}
                          variant="no-effects"
                          readOnly
                          bg="#F9F9F9"
                          border="1px solid black"
                          defaultValue={formView?.medications[index]?.frequency}
                        />
                      </InputGroup>
                    )}
                  />
                </VStack>
              </Box>
            </HStack>
          ))}
        </Box>
      </VStack>
      <Text fontWeight="bold" fontSize="14px" pt="25px" pl="10px">
        Current Medical Problems
      </Text>
      <Box pt="10px">
        <Controller
          name="currentMedicalProblems"
          control={control}
          defaultValue=""
          render={props => (
            <FormControl>
              <Textarea
                _readOnly={{
                  border: '1px solid #ccc',
                }}
                variant="no-effects"
                readOnly
                bg="#F9F9F9"
                border="1px solid black"
                defaultValue={formView?.currentMedicalProblems}
              />
            </FormControl>
          )}
        />
      </Box>
      <Center pt="2rem">{`7/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}
export { RecruitPatientsForm }
